import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { roundMoney } from 'svr/common/Money'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import Button from '../../../../lib/Buttons/Button'
import Switch from '../../../../lib/Switch/Switch'
import { shortDayFormat } from '../../../scripts/utils/constants'
import { changeGratuityPercentage } from '../actions/forms'
import { toggleField, toggleModalDisplay } from '../actions/navigation'
import styles from '../assets/styles/checkout'
import { getSubtotal, calcTotal, calcPercentageProduct } from '../utils/calcCharges'
import { modalTypes } from '../utils/constantTypes'

class CheckoutSummary extends Component {
  constructor(props) {
    super(props)
    /* eslint-disable max-len */
    this.toggleBookingPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.BOOKING_POLICY)
    this.toggleMarketingOptInPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.MARKETING_OPT_IN_POLICY)
    this.toggleDietRestrictionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.DIET_TAG_SELECT)
    this.toggleSpecialOccasionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.OCCASIONS_TAG_SELECT)
    this.toggleReservationNotesModal = this.props.toggleModalDisplay.bind(this, modalTypes.RESERVATION_NOTES)
    this.updateGratuityPercentage = this.props.changeGratuityPercentage.bind(this, 'gratuityPercentage')
    /* eslint-enable max-len */
  }

  buildPriceLine(strA, strB) {
    // eslint-disable-line class-methods-use-this
    return (
      <div>
        <div style={[styles.breakdown, styles.floatLeft]}>{strA}</div>
        <div style={[styles.breakdown, styles.floatRight]}>{strB}</div>
      </div>
    )
  }

  buildSelectTipLine(gratuityPercentage, tipAmount, textCommonPaymentGeneralSelectATipLabel, textCommonPaymentGeneralSelectLabel) {
    const tipOptions = _.map(_.range(41), (val, index) => (
      <option key={index} value={val}>
        {val}%
      </option>
    ))
    return (
      <div>
        <div id="sr-gratuity-line" style={[styles.breakdown, styles.floatLeft]}>
          <span>{textCommonPaymentGeneralSelectATipLabel}</span>
          <select
            onChange={this.updateGratuityPercentage}
            style={{
              WebkitAppearance: 'inherit',
              MozAppearance: 'none',
              width: 'auto',
              minWidth: 55,
              paddingRight: 20,
              paddingLeft: 2,
              height: 21,
              textOverflow: '',
              color: 'inherit',
              borderColor: this.props.formErrors.gratuityPercentage ? '#d01a21' : '#a6a6a6',
              borderRadius: 'inherit',
              backgroundColor: 'transparent',
              backgroundImage:
                'linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc)',
              backgroundPosition: 'calc(100% - 10px) calc(.5em), calc(100% - 5px) calc(.5em), calc(100% - 5px) 2em',
              backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
              backgroundRepeat: 'no-repeat',
            }}
            defaultValue={gratuityPercentage}
          >
            <option value="" selected disabled>
              {textCommonPaymentGeneralSelectLabel}
            </option>
            {tipOptions}
          </select>
        </div>
        <div id="sr-tip-price" style={[styles.breakdown, styles.floatRight]}>
          {tipAmount}
        </div>
      </div>
    )
  }

  buildDateTimeDetails(selected_language_code) {
    const { noTime, selectedTimeSlot, locale } = this.props
    const timeDisplayFormat = locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
    const dateStr = selectedTimeSlot
      .clone()
      .locale(selected_language_code)
      .format(shortDayFormat[locale] || shortDayFormat.default)
    const timeStr = selectedTimeSlot.locale(selected_language_code).format(timeDisplayFormat)
    const dot = <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
    return (
      <div>
        <span style={styles.inline}>{dateStr}</span>
        {noTime ? null : (
          <span style={styles.inline}>
            {dot}
            {timeStr}
          </span>
        )}
      </div>
    )
  }

  render() {
    const {
      onBookingPolicyClick,
      agreedToBookingPolicy,
      partySize,
      cost,
      taxRate,
      serviceChargePercentage,
      currencySymbol,
      gratuityPercentage,
      assignedGratuityPercentage,
      upsellItems,
      upsellCategories,
      serviceChargeAmount,
      upsellQuantities,
      taxAmount,
      reservationBasePrice,
      gratuityAmount,
      upsellAmount,
      selectGratuity,
      chargeType,
      requirePayment,
      isDining,
      fontsColorPrimary,
      fontsColorCheckoutActive,
      message,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      formErrors,
      colorPrimary,
      textWidgetSummaryLabel,
      textCommonTax,
      textWidgetOrderTotalLabel,
      textWidgetUpgradesLabel,
      textWidgetTipLabel,
      textCommonGuestsLabel,
      textCommonGuestLabel,
      textPaylinkPageOrderSubLabel,
      textPaylinkPageCancellation,
      textPaylinkPageCcLabel,
      textPaylinkPageAgreeTo,
      textPaylinkPagePolicy,
      selected_language_code,
      textPaylinkPageServiceCharge,
      textCommonPaymentGeneralSelectATipLabel,
      textCommonPaymentGeneralSelectLabel,
    } = this.props
    const subTotal = getSubtotal(cost, partySize, chargeType)
    const serviceCharge = serviceChargePercentage ? calcPercentageProduct(subTotal, serviceChargePercentage) : roundMoney(0)
    const preTaxTotal = roundMoney(parseFloat(subTotal) + parseFloat(serviceCharge))
    let tax = taxRate ? calcPercentageProduct(preTaxTotal, taxRate) : 0
    const getAmountFormatted = amount => (Number.isInteger(amount) ? amount : amount.toFixed(2))
    const containsUpsells = upsellItems !== null && ('automatic_upsells' in upsellItems || 'selected_upsells' in upsellItems)
    //  Make sure not to include select tip amount if its negative
    const showSelectGratuity = selectGratuity && (reservationBasePrice || subTotal)
    let selectTipAmount =
      gratuityPercentage && selectGratuity
        ? calcPercentageProduct(containsUpsells ? reservationBasePrice : subTotal, gratuityPercentage)
        : roundMoney(0)
    const tipAmount = gratuityAmount && containsUpsells ? gratuityAmount : calcPercentageProduct(subTotal, assignedGratuityPercentage)
    let total = calcTotal(subTotal, serviceCharge, tax, gratuityPercentage && selectGratuity ? selectTipAmount : tipAmount)
    let selectUpsellGratuity = false
    if (containsUpsells) {
      Object.keys(upsellItems.automatic_upsells).forEach(automaticUpsellItemId => {
        const automaticUpsellItem = upsellItems.automatic_upsells[automaticUpsellItemId]
        const upsellQuantity = upsellQuantities.automatic_upsells[automaticUpsellItemId]
        const totalUpsellPrice = upsellQuantity * automaticUpsellItem.promo_pricing[0][0]
        if (upsellCategories[automaticUpsellItem.category_id].gratuity_charge_type === 'CLIENT_GRATUITY' && upsellQuantity > 0) {
          selectTipAmount += calcPercentageProduct(totalUpsellPrice, gratuityPercentage)
          selectUpsellGratuity = true
        }
      })
      Object.keys(upsellItems.selected_upsells).forEach(selectedUpsellItemId => {
        const selectedUpsellItem = upsellItems.selected_upsells[selectedUpsellItemId]
        const upsellQuantity = upsellQuantities.selected_upsells[selectedUpsellItemId]
        if (upsellCategories[selectedUpsellItem.category_id].gratuity_charge_type === 'CLIENT_GRATUITY' && upsellQuantity > 0) {
          selectTipAmount += calcPercentageProduct(selectedUpsellItem.price, gratuityPercentage) * upsellQuantity
          selectUpsellGratuity = true
        }
      })
      tax = roundMoney(taxAmount)
      total = roundMoney(subTotal + serviceChargeAmount + taxAmount + tipAmount + upsellAmount + selectTipAmount)
    }
    return (
      <div style={styles.summaryWrapper}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{textWidgetSummaryLabel}</span>
        </div>
        <div style={styles.infoForm}>
          <div
            style={[
              styles.summaryLine,
              styles.topBorderRadius,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
            ]}
          >
            <i style={styles.lineIcon} className="fa fa-map-marker fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{this.props.venueName}</span>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
            <i style={styles.lineIcon} className="fa fa-time-8 fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{this.buildDateTimeDetails(selected_language_code)}</span>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
            <i style={styles.lineIcon} className="fa fa-users fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{partySize > 1 ? `${partySize} ${textCommonGuestsLabel}` : `1 ${textCommonGuestLabel}`}</span>
          </div>
          {message && (
            <div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
                <i style={styles.lineIcon} className="fa fa-notes fa-fw" aria-hidden="true" />
                <span style={styles.summaryText}>{message}</span>
              </div>
            </div>
          )}
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          {requirePayment && (
            <div>
              <div style={[styles.priceBreakdown, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
                {cost ? this.buildPriceLine(textPaylinkPageOrderSubLabel, `${currencySymbol} ${getAmountFormatted(subTotal)}`) : null}
                {upsellAmount ? this.buildPriceLine(textWidgetUpgradesLabel, `${currencySymbol} ${roundMoney(upsellAmount)}`) : null}
                {/* Possible that there is no service charge percentage (that field only tracks the percentage for the res)
                but we do have a serviceChargeAmount due to auto upsells. We still want to show this in that case */}
                {serviceCharge || serviceChargeAmount
                  ? this.buildPriceLine(
                      `${textPaylinkPageServiceCharge}${!containsUpsells ? ` (${serviceChargePercentage}%)` : ''}`,
                      `${currencySymbol} ${serviceChargeAmount.toFixed(2)}`
                    )
                  : null}
                {tax
                  ? this.buildPriceLine(`${textCommonTax}${!containsUpsells ? ` (${taxRate}%)` : ''}`, `${currencySymbol} ${tax}`)
                  : null}
                {tipAmount
                  ? this.buildPriceLine(
                      `${textWidgetTipLabel}${!containsUpsells ? ` (${gratuityPercentage}%)` : ''}`,
                      `${currencySymbol} ${tipAmount}`
                    )
                  : null}
                {showSelectGratuity || selectUpsellGratuity
                  ? this.buildSelectTipLine(
                      gratuityPercentage,
                      `${currencySymbol} ${selectTipAmount}`,
                      textCommonPaymentGeneralSelectATipLabel,
                      textCommonPaymentGeneralSelectLabel
                    )
                  : null}
                <div style={[styles.lastBreakdown, styles.floatLeft]}>{textWidgetOrderTotalLabel}</div>
                <div style={[styles.lastBreakdown, styles.floatRight]}>{`${currencySymbol} ${getAmountFormatted(total)}`}</div>
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          )}
          <div
            style={[
              styles.policySection,
              styles.bottomBorderRadius,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
              formErrors.agreedToBookingPolicy && {
                border: `2px solid ${colorError}`,
              },
            ]}
          >
            <div style={styles.policy}>
              <span onClick={onBookingPolicyClick} style={[styles.policyLine, formErrors.agreedToBookingPolicy && { color: colorError }]}>
                {textPaylinkPageAgreeTo} {isDining ? textPaylinkPageCancellation : textPaylinkPageCcLabel} {textPaylinkPagePolicy}
              </span>
              <Switch onClick={onBookingPolicyClick} isChecked={agreedToBookingPolicy} checkedColor={colorPrimary} style={styles.switch} />
              <i style={styles.infoIcon} className="fa fa-info-circle fa-fw" onClick={this.toggleBookingPolicyDisplay} aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { selectedTimeSlot } = state.actualInfo
  const partySize = state.actualInfo.guests
  const requirePayment = !!state.billingInfo.amount
  const languageStrings = selectLanguageStrings(state)
  const billinginfoMessages = {
    tax: languageStrings.textCommonPaymentGeneralTaxLabel,
  }
  return {
    selectedTimeSlot,
    partySize,
    requirePayment,
    venueName: state.venueInfo.name,

    noTime: !state.actualInfo.arrival_time,
    locale: state.venueInfo.locale,
    // from billing info
    cost: state.billingInfo.base_amount / 100,
    taxRate: state.billingInfo.tax,
    serviceChargePercentage: state.billingInfo.service_charge_percentage,
    gratuityPercentage: state.formFields.get('gratuityPercentage'),
    assignedGratuityPercentage: state.billingInfo.gratuity_percentage,
    upsellItems: state.billingInfo.upsell_items,
    upsellAmount: state.billingInfo.upsell_amount / 100,
    upsellCategories: state.billingInfo.upsell_categories,
    selectGratuity: state.billingInfo.select_gratuity,
    serviceChargeAmount: state.billingInfo.service_charge_amount,
    upsellQuantities: state.billingInfo.upsell_quantities,
    taxAmount: state.billingInfo.tax_amount / 100,
    reservationBasePrice: state.billingInfo.reservation_base_price,
    gratuityAmount: state.billingInfo.gratuity_amount / 100,

    requireGratuityCharge: state.billingInfo.require_gratuity_charge,
    chargeType: 'reservation',
    message: billinginfoMessages[state.billingInfo.message],

    currencySymbol: state.venueInfo.currencySymbol,
    isDining: state.venueInfo.isDining,
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    // colors
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    colorError: 'rgb(208, 26, 33)',
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    colorLines: state.widgetSettings.colorLines,
    colorPrimary: state.widgetSettings.colorPrimary,
    // errors
    formErrors: state.formFields.get('formErrors').toJS(),

    selected_language_code: state.venueInfo.selectedLanguageCode,
    textWidgetSummaryLabel: languageStrings.textWidgetSummaryLabel,
    textCommonTax: languageStrings.textCommonTax,
    textWidgetTipLabel: languageStrings.textWidgetTipLabel,
    textWidgetUpgradesLabel: languageStrings.textWidgetUpgradesLabel,
    textPaylinkPageServiceCharge: languageStrings.textPaylinkPageServiceCharge,
    textCommonGuestsLabel: languageStrings.textCommonGuestsLabel,
    textCommonGuestLabel: languageStrings.textCommonGuestLabel,
    textPaylinkPageOrderSubLabel: languageStrings.textPaylinkPageOrderSubLabel,
    textPaylinkPageCancellation: languageStrings.textPaylinkPageCancellation,
    textPaylinkPageCcLabel: languageStrings.textPaylinkPageCcLabel,
    textPaylinkPageAgreeTo: languageStrings.textPaylinkPageAgreeTo,
    textPaylinkPagePolicy: languageStrings.textPaylinkPagePolicy,
    textWidgetOrderTotalLabel: languageStrings.textWidgetOrderTotalLabel,
    textCommonPaymentGeneralSelectATipLabel: languageStrings.textCommonPaymentGeneralSelectATipLabel,
    textCommonPaymentGeneralSelectLabel: languageStrings.textCommonPaymentGeneralSelectLabel,
    textCommonPaymentGeneralTaxLabel: languageStrings.textCommonPaymentGeneralTaxLabel,
  }
}

const mapDispatchToProps = dispatch => ({
  onBookingPolicyClick: () => {
    dispatch(toggleField('agreedToBookingPolicy'))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  changeGratuityPercentage: (field, changeTo) => {
    dispatch(changeGratuityPercentage(field, changeTo))
  },
})

CheckoutSummary = connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutSummary))

export default CheckoutSummary
